/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import StyledLink from "../components/StyledLink";

export default () => {
  return (
    <Layout>
      <Styled.h2 sx={{ marginTop: ["2em", "5em"] }}>
        404: Page Not Found
      </Styled.h2>
      <Styled.p sx={{ marginTop: ["3em", "4em"] }}>
        Well, this is awkward. You tried to reach a page that does not exist (at
        least on our end). If you think this is a mistake or a bug please let us
        know.
      </Styled.p>
      Click here to go to the{" "}
      <StyledLink as={Link} to="/">
        home
      </StyledLink>{" "}
      page
    </Layout>
  );
};
